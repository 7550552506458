// src/components/FancyButton.js
import React, { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const ButtonWrapper = styled('div')({
  position: 'relative',
  zIndex: 1,
});

const StyledButton = styled(motion.div)(({ theme }) => ({
  position: 'relative',
  zIndex: 2,
  borderRadius: '50px',
  background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
  padding: '3px',
  boxShadow: '0 0 15px rgba(0,0,0,0.3)',
  transition: 'all 0.3s ease',

  '&:hover': {
    boxShadow: `0 0 30px ${theme.palette.primary.main}, 0 0 60px ${theme.palette.secondary.main}`,
  },

  '& .MuiButton-root': {
    background: theme.palette.background.paper,
    borderRadius: '47px',
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    padding: theme.spacing(2, 6),
    fontSize: '1.5rem',
    textTransform: 'none',
    transition: 'background 0.3s ease',

    '&:hover': {
      background: 'transparent',
      color: theme.palette.background.paper,
    },
  },
}));

const ParticleContainer = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 1,
  overflow: 'visible',
});

const Particle = styled(motion.div)(({ color }) => ({
  position: 'absolute',
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  background: color,
}));

const FancyButton = ({ onClick, children }) => {
  const [isExploding, setIsExploding] = useState(false);
  const buttonRef = useRef(null);

  const handleClick = () => {
    setIsExploding(true);
    setTimeout(() => {
      setIsExploding(false);
      onClick();
    }, 500);
  };

  const particleColors = ['#ff00ff', '#00ffff', '#ffff00', '#ff3333', '#33ff33'];

  return (
    <ButtonWrapper>
      <ParticleContainer>
        {isExploding && (
          [...Array(75)].map((_, i) => {
            const angle = Math.random() * Math.PI * 2;
            const distance = Math.random() * 150 + 50; // More random distance
            const color = particleColors[Math.floor(Math.random() * particleColors.length)];
            const size = Math.random() * 6 + 4; // Random size between 4-10px
            return (
              <Particle
                key={i}
                color={color}
                style={{
                  width: size,
                  height: size,
                  bottom: 0,
                  left: '50%',
                }}
                initial={{ 
                  x: 0,
                  y: 0,
                  opacity: 1,
                }}
                animate={{
                  x: Math.cos(angle) * distance,
                  y: Math.sin(angle) * distance * -1, // Negative to go upwards
                  opacity: 0,
                }}
                transition={{ 
                  duration: Math.random() * 0.4 + 0.3, // Random duration
                  ease: 'easeOut',
                }}
              />
            );
          })
        )}
      </ParticleContainer>
      <StyledButton
        ref={buttonRef}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        <Button onClick={handleClick}>
          {children}
        </Button>
      </StyledButton>
    </ButtonWrapper>
  );
};

export default FancyButton;