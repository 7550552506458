// src/components/UserDetails.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { 
  Paper, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Box, Typography, TextField, Grid 
} from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { green } from '@mui/material/colors';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import { useAuth } from '../contexts/AuthContext';
import { useTheme } from '../contexts/ThemeContext';
import api from '../utils/api';
import UserAvatar from './UserAvatar';
import UserRoles from './UserRoles';
import UserActivityChart from './UserActivityChart';
import WarningsList from './WarningsList';
import EmojiUsage from './EmojiUsage';
import ActivityHeatmap from './ActivityHeatmap';

const FullscreenBox = styled(Box)({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  boxSizing: 'border-box',
  overflowX: 'hidden',
  width: '100%',
});

const StyledPaper = styled(motion(Paper))(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  width: '100%',
  maxWidth: '1200px',
  margin: '0 auto',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
  borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
  '&:hover': {
    borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));
function UserDetails() {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [actionType, setActionType] = useState('');
  const [reason, setReason] = useState('');
  const [duration, setDuration] = useState('');
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [newWarningReason, setNewWarningReason] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [activityPeriod, setActivityPeriod] = useState(30);
  const [activityData, setActivityData] = useState(null);
  const [emojiUsage, setEmojiUsage] = useState([]);
  const [activityHeatmap, setActivityHeatmap] = useState([]);
  const { user: authUser } = useAuth();
  const { mode } = useTheme();

  useEffect(() => {
    fetchUserDetails();
    fetchUserActivity();
    fetchEmojiUsage();
    fetchActivityHeatmap();
  }, [userId, activityPeriod]);

  const fetchUserActivity = async () => {
    try {
      const response = await api.get(`/users/${userId}/activity?period=${activityPeriod}`);
      setActivityData(response.data.data);
    } catch (error) {
      console.error('Error fetching user activity:', error);
      setError('Failed to fetch user activity');
    }
  };

  const fetchUserDetails = async () => {
    try {
      const response = await api.get(`/users/${userId}`);
      const userData = response.data.data;
      // Ensure warnings is always an array
      userData.warnings = userData.warnings || [];
      setUser(userData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user details:', error);
      setError('Failed to fetch user details');
      setLoading(false);
    }
  };

  const fetchEmojiUsage = async () => {
    try {
      const response = await api.get(`/users/${userId}/emoji-usage`);
      setEmojiUsage(response.data.data || []);
    } catch (error) {
      console.error('Error fetching emoji usage:', error);
      setError('Failed to fetch emoji usage');
    }
  };

  const fetchActivityHeatmap = async () => {
    try {
      const response = await api.get(`/users/${userId}/activity-heatmap`);
      setActivityHeatmap(response.data.data || []);
    } catch (error) {
      console.error('Error fetching activity heatmap:', error);
      setError('Failed to fetch activity heatmap');
    }
  };

  const handleAction = (action) => {
    setActionType(action);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setReason('');
    setDuration('');
  };

  const handleConfirmAction = async () => {
    try {
      let response;
      switch (actionType) {
        case 'ban':
          response = await api.post(`/users/${userId}/ban`, { reason });
          break;
        case 'kick':
          response = await api.post(`/users/${userId}/kick`, { reason });
          break;
        case 'timeout':
          response = await api.post(`/users/${userId}/timeout`, { reason, duration });
          break;
        default:
          throw new Error('Invalid action type');
      }
      console.log(response.data.message);
      handleCloseDialog();
      setConfirmationMessage(`User has been ${actionType}ed successfully`);
      setShowConfirmation(true);
      setTimeout(() => setShowConfirmation(false), 3000);
    } catch (error) {
      console.error(`Error ${actionType} user:`, error);
      setError(`Failed to ${actionType} user`);
    }
  };

  const handleAddWarning = async () => {
    try {
      const response = await api.post(`/users/${userId}/warnings`, { reason: newWarningReason });
      const newWarning = response.data.data;

      setUser(prevUser => ({
        ...prevUser,
        warnings: [...(prevUser.warnings || []), newWarning]
      }));
      setOpenWarningDialog(false);
      setNewWarningReason('');
      setConfirmationMessage('Warning added successfully');
      setShowConfirmation(true);
      setTimeout(() => setShowConfirmation(false), 3000);
    } catch (error) {
      console.error('Error adding warning:', error);
      setError('Failed to add warning');
    }
  };

  const handleRemoveWarning = async (warningId) => {
    try {
      await api.delete(`/users/${userId}/warnings/${warningId}`);
      setUser(prevUser => ({
        ...prevUser,
        warnings: prevUser.warnings.filter(warning => warning.id !== warningId)
      }));
    } catch (error) {
      console.error('Error removing warning:', error);
      setError('Failed to remove warning');
    }
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!user) return <Typography>User not found</Typography>;
  if (!authUser) return <Typography>Please log in to view user details</Typography>;

  return (
    <FullscreenBox>
      <StyledPaper
        elevation={6}
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <UserAvatar user={user} mode={mode} />
        <Box width="100%" px={3}>
          <Typography
            variant="h6"
            sx={{ color: mode === "dark" ? "white" : "black", mt: 2, mb: 1 }}
          >
            Joined Server: {new Date(user.joinedAt).toLocaleString()}
          </Typography>
          <Typography
            variant="h6"
            sx={{ color: mode === "dark" ? "white" : "black", mb: 3 }}
          >
            Account Created: {new Date(user.createdAt).toLocaleString()}
          </Typography>
          <Typography
            variant="h6"
            sx={{ color: mode === "dark" ? "white" : "black", mb: 1 }}
          >
            Total Messages: {user.total_messages?.toLocaleString() || '0'}
          </Typography>
          <Typography
            variant="h6"
            sx={{ color: mode === "dark" ? "white" : "black", mb: 1 }}
          >
            Last Seen: {user.last_seen ? new Date(user.last_seen * 1000).toLocaleString() : 'Never'}
          </Typography>
          <Typography
            variant="h6"
            sx={{ color: mode === "dark" ? "white" : "black", mb: 3 }}
          >
            First Seen: {user.first_seen ? new Date(user.first_seen * 1000).toLocaleString() : 'Never'}
          </Typography>
        </Box>
        <UserRoles roles={user.roles || []} mode={mode} />

        <UserActivityChart
          activityData={activityData}
          activityPeriod={activityPeriod}
          setActivityPeriod={setActivityPeriod}
          mode={mode}
        />

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <EmojiUsage emojiUsage={emojiUsage} mode={mode} />
          </Grid>
          <Grid item xs={12} md={6}>
            <ActivityHeatmap heatmapData={activityHeatmap} mode={mode} />
          </Grid>
        </Grid>

        <Box mt={3} sx={{ width: "100%" }}>
          <Typography
            variant="h5"
            sx={{ color: mode === "dark" ? "white" : "black" }}
          >
            Warnings
          </Typography>
          <StyledButton
            startIcon={<AddIcon />}
            variant="outlined"
            onClick={() => setOpenWarningDialog(true)}
            sx={{ mt: 2, mb: 2 }}
          >
            Add Warning
          </StyledButton>
          {(user.warnings || []).length === 0 ? (
            <Typography sx={{ color: mode === "dark" ? "white" : "black" }}>
              No warnings for this user.
            </Typography>
          ) : (
            <WarningsList
              warnings={user.warnings || []}
              onRemoveWarning={handleRemoveWarning}
              mode={mode}
            />
          )}
        </Box>

        <Box
          mt={3}
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            gap: 2,
          }}
        >
          <StyledButton variant="outlined" onClick={() => handleAction("kick")}>
            Kick
          </StyledButton>
          <StyledButton variant="outlined" onClick={() => handleAction("ban")}>
            Ban
          </StyledButton>
          <StyledButton
            variant="outlined"
            onClick={() => handleAction("timeout")}
          >
            Timeout
          </StyledButton>
        </Box>
      </StyledPaper>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{`Confirm ${actionType}`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {actionType} this user?
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Reason"
            fullWidth
            variant="outlined"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
          {actionType === "timeout" && (
            <TextField
              margin="dense"
              label="Duration (minutes)"
              fullWidth
              variant="outlined"
              type="number"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
            />
          )}
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={handleCloseDialog}>Cancel</StyledButton>
          <StyledButton
            onClick={handleConfirmAction}
            color="primary"
            variant="contained"
          >
            Confirm
          </StyledButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openWarningDialog}
        onClose={() => setOpenWarningDialog(false)}
      >
        <DialogTitle>Add Warning</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the reason for the warning.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Reason"
            fullWidth
            variant="outlined"
            value={newWarningReason}
            onChange={(e) => setNewWarningReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={() => setOpenWarningDialog(false)}>
            Cancel
          </StyledButton>
          <StyledButton
            onClick={handleAddWarning}
            color="primary"
            variant="contained"
          >
            Add Warning
          </StyledButton>
        </DialogActions>
      </Dialog>

      <AnimatePresence>
        {showConfirmation && (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            style={{
              position: "fixed",
              bottom: 20,
              right: 20,
              backgroundColor: green[500],
              color: "white",
              padding: "10px 20px",
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
              zIndex: 9999,
            }}
          >
            <CheckIcon style={{ marginRight: 10 }} />
            {confirmationMessage}
          </motion.div>
        )}
      </AnimatePresence>
    </FullscreenBox>
  );
}

export default UserDetails;
