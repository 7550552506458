import React, { useState, useEffect } from 'react';
import {
  List,
  ListItem,
  Typography,
  Box,
  Paper,
  CircularProgress,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Pagination,
  IconButton,
  Tooltip,
  Menu,
  Chip,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Autocomplete
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '../contexts/ThemeContext';
import InfoIcon from '@mui/icons-material/Info';
import FilterListIcon from '@mui/icons-material/FilterList';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import api from '../utils/api';

const FullscreenBox = styled(Box)({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  boxSizing: 'border-box',
  overflowX: 'hidden',
});

const StyledPaper = styled(motion(Paper))(({ theme }) => ({
  padding: theme.spacing(4),
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  width: '100%',
  maxWidth: '1200px',
  margin: '20px auto',
}));

const AnimatedListItem = styled(motion(ListItem))(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(5px)',
  borderRadius: '8px',
  marginBottom: '8px',
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.2)',
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    color: theme.palette.mode === 'dark' ? 'white' : 'black',
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.mode === 'dark' ? 'white' : 'black',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
    },
    '&:hover fieldset': {
      borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
    },
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.mode === 'dark' ? 'white' : 'black',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
  borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
  '&:hover': {
    borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const actionTypes = [
  'Message Delete', 'Message Update', 'Guild Member Update', 'Channel Create', 'Channel Delete', 'Channel Update',
  'Role Create', 'Role Delete', 'Role Update', 'Ban Add', 'Ban Remove', 'Guild Update',
  'Emoji Create', 'Emoji Update', 'Emoji Delete', 'Sticker Create', 'Sticker Update', 'Sticker Delete',
  'Invite Create', 'Invite Delete', 'Webhook Create', 'Webhook Update', 'Webhook Delete',
  'Thread Create', 'Thread Update', 'Thread Delete', 'User Update', 'Voice State Update', 'Warning Issued',
  'Remove Warning', 'Channel Lockdown', 'Channel Unlock', 'User Muted', 'User Unmuted', 'User Kicked',
  'User Banned', 'User Unbanned'
];

const actionColors = {
  'Message Delete': '#FF6B6B',
  'Message Update': '#4ECDC4',
  'Guild Member Update': '#45B7D1',
  'Channel Create': '#98D8C8',
  'Channel Delete': '#FF8A5B',
  'Channel Update': '#FECA57',
  'Role Create': '#FF9FF3',
  'Role Delete': '#F368E0',
  'Role Update': '#FF9FF3',
  'Ban Add': '#EE5253',
  'Ban Remove': '#5CCB5F',
  'Guild Update': '#54A0FF',
  'Warning Issued': '#FFA500',
  'Remove Warning': '#32CD32',
  'Channel Lockdown': '#FF4500',
  'Channel Unlock': '#1E90FF',
  'User Muted': '#8A2BE2',
  'User Unmuted': '#20B2AA',
  'User Kicked': '#FF6347',
  'User Banned': '#DC143C',
  'User Unbanned': '#3CB371'
};

function AuditLogList() {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('timestamp');
  const [sortOrder, setSortOrder] = useState('DESC');
  const [actionTypeFilter, setActionTypeFilter] = useState('');
  const [userFilter, setUserFilter] = useState('');
  const [channelFilter, setChannelFilter] = useState('');
  const [dateRange, setDateRange] = useState([null, null]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [expandedLogId, setExpandedLogId] = useState(null);
  const [detailDialogOpen, setDetailDialogOpen] = useState(false);
  const [selectedLog, setSelectedLog] = useState(null);
  const [users, setUsers] = useState([]);
  const [channels, setChannels] = useState([]);
  const { mode } = useTheme();

  useEffect(() => {
    fetchLogs();
    fetchUsers();
    fetchChannels();
  }, [page, search, sortBy, sortOrder, actionTypeFilter, userFilter, channelFilter, dateRange]);

  const fetchLogs = async () => {
    setLoading(true);
    try {
      const response = await api.get('/audit-logs', {
        params: {
          search,
          page,
          limit: 50,
          sortBy,
          sortOrder,
          actionType: actionTypeFilter,
          userId: userFilter,
          channelId: channelFilter,
          startDate: dateRange[0]?.toISOString(),
          endDate: dateRange[1]?.toISOString()
        }
      });

      const data = response.data.data;
      setLogs(data.logs);
      setTotalPages(data.pagination.totalPages);
    } catch (error) {
      console.error('Error fetching audit logs:', error);
      setError('Failed to fetch audit logs. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await api.get('/users');
      setUsers(response.data.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchChannels = async () => {
    try {
      const response = await api.get('/channels');
      setChannels(response.data.data);
    } catch (error) {
      console.error('Error fetching channels:', error);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setPage(1);
  };

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
    setPage(1);
  };

  const handleSortOrderChange = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    setPage(1);
  };

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleActionTypeFilterChange = (event) => {
    setActionTypeFilter(event.target.value);
    setPage(1);
  };

  const handleUserFilterChange = (event, newValue) => {
    setUserFilter(newValue ? newValue.id : '');
    setPage(1);
  };

  const handleChannelFilterChange = (event, newValue) => {
    setChannelFilter(newValue ? newValue.id : '');
    setPage(1);
  };

  const handleDateRangeChange = (newDateRange) => {
    setDateRange(newDateRange);
    setPage(1);
  };

  const handleExpandLog = (logId) => {
    setExpandedLogId(expandedLogId === logId ? null : logId);
  };

  const handleOpenDetailDialog = (log) => {
    setSelectedLog(log);
    setDetailDialogOpen(true);
  };

  const handleCloseDetailDialog = () => {
    setDetailDialogOpen(false);
  };

  const formatTimestamp = (timestamp) => {
    return new Date(timestamp).toLocaleString();
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.05
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  return (
    <FullscreenBox>
      <StyledPaper
        elevation={6}
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <Typography variant="h4" sx={{ color: mode === 'dark' ? 'white' : 'black' }}>
            Audit Logs
          </Typography>
          <Box display="flex" gap={1}>
            <Tooltip title="Sort Order">
              <StyledButton
                variant="outlined"
                onClick={handleSortOrderChange}
                sx={{ minWidth: 0, padding: '6px' }}
              >
                {sortOrder === 'asc' ? '↑' : '↓'}
              </StyledButton>
            </Tooltip>
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel sx={{ color: mode === 'dark' ? 'white' : 'black' }}>Sort By</InputLabel>
              <StyledSelect
                value={sortBy}
                onChange={handleSortChange}
                label="Sort By"
              >
                <MenuItem value="timestamp">Time</MenuItem>
                <MenuItem value="action_type">Action</MenuItem>
                <MenuItem value="user_id">User</MenuItem>
              </StyledSelect>
            </FormControl>
            <Tooltip title="Filter">
              <StyledButton
                variant="outlined"
                onClick={handleFilterClick}
                startIcon={<FilterListIcon />}
                sx={{
                  backgroundColor: (actionTypeFilter || userFilter || channelFilter || dateRange[0] || dateRange[1])
                    ? 'rgba(255, 255, 255, 0.1)'
                    : 'transparent'
                }}
              >
                Filter
              </StyledButton>
            </Tooltip>
          </Box>
        </Box>

        <StyledTextField
          label="Search"
          variant="outlined"
          value={search}
          onChange={handleSearchChange}
          fullWidth
          sx={{ mb: 3 }}
        />

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleFilterClose}
          PaperProps={{
            style: {
              backgroundColor: mode === 'dark' ? 'rgba(0, 0, 0, 0.9)' : 'rgba(255, 255, 255, 0.9)',
              backdropFilter: 'blur(10px)',
              padding: '16px',
              minWidth: '250px'
            }
          }}
        >
          <Box p={2}>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel sx={{ color: mode === 'dark' ? 'white' : 'black' }}>Action Type</InputLabel>
              <StyledSelect
                value={actionTypeFilter}
                onChange={handleActionTypeFilterChange}
                label="Action Type"
              >
                <MenuItem value="">All Actions</MenuItem>
                {actionTypes.map(type => (
                  <MenuItem key={type} value={type}>{type}</MenuItem>
                ))}
              </StyledSelect>
            </FormControl>

            <Autocomplete
              options={users}
              getOptionLabel={(option) => option.username}
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  label="User"
                  variant="outlined"
                />
              )}
              onChange={handleUserFilterChange}
              sx={{ mb: 2 }}
            />

            <Autocomplete
              options={channels}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  label="Channel"
                  variant="outlined"
                />
              )}
              onChange={handleChannelFilterChange}
            />
          </Box>
        </Menu>

        {loading ? (
          <Box display="flex" justifyContent="center" p={4}>
            <CircularProgress sx={{ color: mode === 'dark' ? 'white' : 'black' }} />
          </Box>
        ) : error ? (
          <Typography color="error" align="center" p={4}>{error}</Typography>
        ) : logs.length === 0 ? (
          <Typography align="center" sx={{ color: mode === 'dark' ? 'white' : 'black' }} p={4}>
            No audit logs found
          </Typography>
        ) : (
          <AnimatePresence mode="wait">
            <motion.div
              variants={containerVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
            >
              <List>
                {logs.map((log) => (
                  <AnimatedListItem
                    key={log.id}
                    variants={itemVariants}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    sx={{
                      borderLeft: `4px solid ${actionColors[log.action_type] || '#888'}`,
                    }}
                  >
                    <Box width="100%">
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="subtitle1" sx={{ color: mode === 'dark' ? 'white' : 'black' }}>
                          {log.action_type}
                        </Typography>
                        <Box display="flex" alignItems="center">
                          <Tooltip title={formatTimestamp(log.timestamp)}>
                            <Chip
                              label={new Date(log.timestamp).toLocaleDateString()}
                              size="small"
                              sx={{
                                backgroundColor: mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
                                color: mode === 'dark' ? 'white' : 'black',
                                mr: 1
                              }}
                            />
                          </Tooltip>
                          <IconButton
                            size="small"
                            onClick={() => handleExpandLog(log.id)}
                            sx={{ color: mode === 'dark' ? 'white' : 'black' }}
                          >
                            {expandedLogId === log.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={() => handleOpenDetailDialog(log)}
                            sx={{ color: mode === 'dark' ? 'white' : 'black' }}
                          >
                            <InfoIcon />
                          </IconButton>
                        </Box>
                      </Box>

                      <Collapse in={expandedLogId === log.id}>
                        <Box mt={1} sx={{ color: mode === 'dark' ? 'rgba(255,255,255,0.7)' : 'rgba(0,0,0,0.7)' }}>
                          <Typography variant="body2">
                            By: {log.moderator_name || 'System'}
                          </Typography>
                          {log.target_name && (
                            <Typography variant="body2">
                              Target: {log.target_name}
                            </Typography>
                          )}
                          {log.reason && (
                            <Typography variant="body2">
                              Reason: {log.reason}
                            </Typography>
                          )}
                          {log.details && (
                            <Box
                              sx={{
                                mt: 1,
                                p: 1,
                                backgroundColor: mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)',
                                borderRadius: 1,
                                fontFamily: 'monospace',
                                fontSize: '0.875rem',
                                overflowX: 'auto'
                              }}
                            >
                              <pre style={{ margin: 0 }}>
                                {JSON.stringify(log.details, null, 2)}
                              </pre>
                            </Box>
                          )}
                        </Box>
                      </Collapse>
                    </Box>
                  </AnimatedListItem>
                ))}
              </List>
            </motion.div>
          </AnimatePresence>
        )}

        {!loading && logs.length > 0 && (
          <Box display="flex" justifyContent="center" mt={3}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
              sx={{
                '& .MuiPaginationItem-root': {
                  color: mode === 'dark' ? 'white' : 'black'
                }
              }}
            />
          </Box>
        )}
      </StyledPaper>

      <Dialog
        open={detailDialogOpen}
        onClose={handleCloseDetailDialog}
        PaperProps={{
          style: {
            backgroundColor: mode === 'dark' ? 'rgba(0, 0, 0, 0.9)' : 'rgba(255, 255, 255, 0.9)',
            backdropFilter: 'blur(10px)',
            maxWidth: '600px',
            width: '100%'
          }
        }}
      >
        {selectedLog && (
          <>
            <DialogTitle sx={{ color: mode === 'dark' ? 'white' : 'black' }}>
              {selectedLog.action_type}
            </DialogTitle>
            <DialogContent>
              <Box sx={{ color: mode === 'dark' ? 'rgba(255,255,255,0.7)' : 'rgba(0,0,0,0.7)' }}>
                <Typography gutterBottom>
                  Time: {formatTimestamp(selectedLog.timestamp)}
                </Typography>
                <Typography gutterBottom>
                  By: {selectedLog.moderator_name || 'System'}
                </Typography>
                {selectedLog.target_name && (
                  <Typography gutterBottom>
                    Target: {selectedLog.target_name}
                  </Typography>
                )}
                {selectedLog.reason && (
                  <Typography gutterBottom>
                    Reason: {selectedLog.reason}
                  </Typography>
                )}
                {selectedLog.details && (
                  <Box
                    sx={{
                      mt: 2,
                      p: 2,
                      backgroundColor: mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)',
                      borderRadius: 1,
                      fontFamily: 'monospace',
                      fontSize: '0.875rem',
                      overflowX: 'auto'
                    }}
                  >
                    <pre style={{ margin: 0 }}>
                      {JSON.stringify(selectedLog.details, null, 2)}
                    </pre>
                  </Box>
                )}
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseDetailDialog}
                sx={{ color: mode === 'dark' ? 'white' : 'black' }}
              >
                Close
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </FullscreenBox>
  );
}

export default AuditLogList;
