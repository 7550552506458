// src/components/ChannelList.js
import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Typography, Box, Paper, Collapse, TextField, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';
import api from '../utils/api';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import TextsmsIcon from '@mui/icons-material/Textsms';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import SearchIcon from '@mui/icons-material/Search';
import { motion, AnimatePresence } from 'framer-motion';
import { styled } from '@mui/material/styles';

const FullscreenBox = styled(Box)({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  boxSizing: 'border-box',
  overflowX: 'hidden',
});

const StyledPaper = styled(motion(Paper))(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  width: '100%',
  maxWidth: '800px',
}));

const AnimatedListItem = styled(motion(ListItem))(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(5px)',
  borderRadius: '8px',
  marginBottom: '8px',
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.2)',
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    color: theme.palette.mode === 'dark' ? 'white' : 'black',
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.mode === 'dark' ? 'white' : 'black',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
    },
    '&:hover fieldset': {
      borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
    },
  },
}));

function ChannelList() {
  const [channels, setChannels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openCategories, setOpenCategories] = useState({});
  const [search, setSearch] = useState('');
  const { mode } = useTheme();

  useEffect(() => {
    fetchChannels();
  }, []);

  const fetchChannels = async () => {
    try {
      setLoading(true);
      const response = await api.get('/channels');
      const channelsData = response.data.data;
      setChannels(channelsData);
      
      // Initialize all categories as open
      const initialOpenState = {};
      channelsData.forEach(category => {
        initialOpenState[category.id] = true;
      });
      setOpenCategories(initialOpenState);
      
      setLoading(false);
    } catch (error) {
      console.error('Error fetching channels:', error);
      setError('Failed to fetch channels. Please try again later.');
      setLoading(false);
    }
  };

  const handleCategoryClick = (categoryId) => {
    setOpenCategories(prev => ({ ...prev, [categoryId]: !prev[categoryId] }));
  };

  const getChannelIcon = (type) => {
    switch(type) {
      case 'text': return <TextsmsIcon />;
      case 'voice': return <VolumeUpIcon />;
      case 'announcement': return <AnnouncementIcon />;
      default: return null;
    }
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value.toLowerCase());
  };

  const filteredChannels = channels.map(category => ({
    ...category,
    channels: category.channels.filter(channel => 
      channel.name.toLowerCase().includes(search)
    )
  })).filter(category => 
    category.channels.length > 0 || category.name.toLowerCase().includes(search)
  );

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.05
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  const renderChannelItem = (channel) => (
    <AnimatedListItem
      button
      key={channel.id}
      component={channel.type !== 'voice' ? Link : 'div'}
      to={channel.type !== 'voice' ? `/channels/${channel.id}` : undefined}
      sx={{ pl: 4 }}
      variants={itemVariants}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
    >
      <ListItemIcon sx={{ color: mode === 'dark' ? 'white' : 'black' }}>
        {getChannelIcon(channel.type)}
      </ListItemIcon>
      <ListItemText 
        primary={
          <Typography sx={{ color: mode === 'dark' ? 'white' : 'black' }}>
            {channel.name}
          </Typography>
        }
        secondary={channel.topic && (
          <Typography 
            variant="body2" 
            sx={{ 
              color: mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)',
              fontSize: '0.8rem'
            }}
          >
            {channel.topic}
          </Typography>
        )}
      />
    </AnimatedListItem>
  );

  return (
    <FullscreenBox>
      <StyledPaper 
        elevation={6}
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Typography variant="h4" sx={{ color: mode === 'dark' ? 'white' : 'black', mb: 3 }}>Channels</Typography>
        </motion.div>
        <Box display="flex" flexDirection="column" gap={2} mb={2} width="100%">
          <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
            <StyledTextField
              label="Search channels"
              variant="outlined"
              fullWidth
              value={search}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: <SearchIcon sx={{ color: mode === 'dark' ? 'white' : 'black', mr: 1 }} />,
              }}
            />
          </motion.div>
        </Box>
        {loading ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <CircularProgress sx={{ color: mode === 'dark' ? 'white' : 'black' }} />
          </motion.div>
        ) : error ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Typography sx={{ color: mode === 'dark' ? 'white' : 'black' }}>{error}</Typography>
          </motion.div>
        ) : (
          <AnimatePresence mode="wait">
            <motion.div
              key={search}
              variants={containerVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              style={{ width: '100%' }}
            >
              <List>
                {filteredChannels.map((category) => (
                  <React.Fragment key={category.id}>
                    <AnimatedListItem 
                      button 
                      onClick={() => handleCategoryClick(category.id)}
                      variants={itemVariants}
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      <ListItemText 
                        primary={
                          <Typography sx={{ color: mode === 'dark' ? 'white' : 'black', fontWeight: 'bold' }}>
                            {category.name}
                          </Typography>
                        } 
                      />
                      {openCategories[category.id] ? <ExpandLess /> : <ExpandMore />}
                    </AnimatedListItem>
                    <Collapse in={openCategories[category.id]} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <AnimatePresence>
                          {category.channels.map(renderChannelItem)}
                        </AnimatePresence>
                      </List>
                    </Collapse>
                  </React.Fragment>
                ))}
              </List>
            </motion.div>
          </AnimatePresence>
        )}
      </StyledPaper>
    </FullscreenBox>
  );
}

export default ChannelList;
