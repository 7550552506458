// src/contexts/AuthContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import api from '../utils/api';

const AuthContext = createContext();

const API_URL = process.env.REACT_APP_API_URL;

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkAuth();
  }, []);

  const login = async (username, password) => {
    try {
      console.log('Attempting login...');
      const response = await api.post('/login', { username, password });
      console.log('Login response:', response.data);
      
      // Fix token extraction - it's inside the data object
      const token = response.data.data.token;
      console.log('Extracted token:', token ? 'exists' : 'not found');
      
      if (token) {
        localStorage.setItem('token', token);
        // Fetch user data immediately after successful login
        await checkAuth();
        return true; // Indicate successful login
      } else {
        console.error('No token in response');
        return false;
      }
    } catch (error) {
      console.error('Login error:', error);
      return false; // Indicate failed login
    }
  };
  
  const checkAuth = async () => {
    try {
      const token = localStorage.getItem('token');
      if (token) {
        // Let the api interceptor handle the token header
        const response = await api.get('/login/me');
        console.log('Auth check response:', response.data);
        setUser(response.data);
      } else {
        console.log('No token found during auth check');
        setUser(null);
      }
    } catch (error) {
      console.error('Check auth error:', error);
      localStorage.removeItem('token');
      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, loading, login, logout, checkAuth }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
