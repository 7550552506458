// src/components/ActivityHeatmap.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import { ResponsiveContainer, ScatterChart, XAxis, YAxis, ZAxis, Scatter, Tooltip } from 'recharts';
import { scaleLinear } from 'd3-scale';

const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const keyHours = [0, 6, 12, 18, 23];

function ActivityHeatmap({ heatmapData, mode }) {
  if (!heatmapData || heatmapData.length === 0) {
    return (
      <Box mt={3} sx={{ width: '100%' }}>
        <Typography variant="h5" sx={{ color: mode === 'dark' ? 'white' : 'black', mb: 2 }}>Activity Heatmap</Typography>
        <Typography>No activity data available.</Typography>
      </Box>
    );
  }

  const data = heatmapData.map(item => ({
    x: parseInt(item.hour),
    y: (parseInt(item.day_of_week) + 6) % 7, // Adjust to make Monday the first day
    z: parseInt(item.count)
  }));

  const maxValue = Math.max(...data.map(item => item.z));
  const colorScale = scaleLinear()
    .domain([0, maxValue])
    .range(mode === 'dark' ? ["#1a1a1a", "#8884d8"] : ["#f1f1f1", "#82ca9d"]);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      const hour12 = data.x % 12 || 12;
      const ampm = data.x >= 12 ? 'PM' : 'AM';
      return (
        <Box
          sx={{
            backgroundColor: mode === 'dark' ? 'rgba(0, 0, 0, 0.8)' : 'rgba(255, 255, 255, 0.8)',
            backdropFilter: 'blur(10px)',
            border: '1px solid rgba(255, 255, 255, 0.2)',
            borderRadius: '10px',
            padding: '10px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            color: mode === 'dark' ? 'white' : 'black',
          }}
        >
          <Typography variant="body2">{`${daysOfWeek[data.y]}, ${hour12}:00 ${ampm}`}</Typography>
          <Typography variant="body2">{`Messages: ${data.z}`}</Typography>
        </Box>
      );
    }
    return null;
  };

  return (
    <Box mt={3} sx={{ width: '100%', height: '500px' }}>
      <Typography variant="h5" sx={{ color: mode === 'dark' ? 'white' : 'black', mb: 2 }}>Activity Heatmap</Typography>
      <ResponsiveContainer width="100%" height="100%">
        <ScatterChart
          margin={{ top: 20, right: 30, bottom: 70, left: 60 }}
        >
          <XAxis 
            type="number" 
            dataKey="x" 
            name="hour" 
            domain={[0, 23]}
            tickCount={5}
            ticks={keyHours}
            tickFormatter={(hour) => {
              const ampm = hour >= 12 ? 'PM' : 'AM';
              const hour12 = hour % 12 || 12;
              return `${hour12}${ampm}`;
            }}
            stroke={mode === 'dark' ? '#888' : '#666'}
            axisLine={false}
            tickLine={false}
          />
          <YAxis 
            type="number" 
            dataKey="y" 
            name="day" 
            domain={[0, 6]} 
            tickCount={7}
            tickFormatter={(day) => daysOfWeek[day]}
            stroke={mode === 'dark' ? '#888' : '#666'}
            width={60}
            axisLine={false}
            tickLine={false}
          />
          <ZAxis type="number" dataKey="z" range={[50, 400]} />
          <Tooltip content={<CustomTooltip />} />
          <Scatter data={data}>
            {data.map((entry, index) => (
              <Scatter
                key={`cell-${index}`}
                fill={colorScale(entry.z)}
                fillOpacity={0.8}
              />
            ))}
          </Scatter>
        </ScatterChart>
      </ResponsiveContainer>
    </Box>
  );
}

export default ActivityHeatmap;
