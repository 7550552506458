// src/components/EmojiUsage.js
import React from 'react';
import { Paper, Typography, Box, List, ListItem, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';

const StyledPaper = styled(motion(Paper))(({ theme }) => ({
  padding: theme.spacing(3),
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  width: '100%',
  height: '100%',
  minHeight: '300px',
}));

const EmojiBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  fontSize: '1.5rem',
});

const ProgressBar = styled(Box)(({ theme, value }) => ({
  height: '4px',
  width: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  borderRadius: '2px',
  marginTop: '4px',
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    height: '100%',
    width: `${value}%`,
    backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#000',
    borderRadius: '2px',
    transition: 'width 0.5s ease-in-out',
  },
}));

function EmojiUsage({ emojiUsage = [], mode }) {
  // Find the maximum count for percentage calculation
  const maxCount = Math.max(...emojiUsage.map(item => item.count), 1);

  return (
    <StyledPaper
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Typography
        variant="h6"
        sx={{
          color: mode === 'dark' ? 'white' : 'black',
          mb: 2,
          textAlign: 'center'
        }}
      >
        Most Used Emojis
      </Typography>
      {emojiUsage.length === 0 ? (
        <Typography
          sx={{
            color: mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)',
            textAlign: 'center',
            mt: 4
          }}
        >
          No emoji usage data available
        </Typography>
      ) : (
        <List>
          {emojiUsage.map((item, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={
                  <Box sx={{ mb: 1 }}>
                    <EmojiBox>
                      <span>{item.emoji}</span>
                      <Typography
                        sx={{
                          color: mode === 'dark' ? 'white' : 'black',
                          fontSize: '0.9rem'
                        }}
                      >
                        {item.count.toLocaleString()} uses
                      </Typography>
                    </EmojiBox>
                    <ProgressBar value={(item.count / maxCount) * 100} />
                  </Box>
                }
              />
            </ListItem>
          ))}
        </List>
      )}
    </StyledPaper>
  );
}

export default EmojiUsage;
