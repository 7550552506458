// src/components/ThankYou.js
import React from 'react';
import { Box, Typography, Button, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';

const StyledPaper = styled(motion(Paper))(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  width: '100%',
  maxWidth: '800px',
  margin: '0 auto',
}));

function ThankYou() {
  const navigate = useNavigate();
  const { mode } = useTheme();

  return (
    <Box sx={{ paddingTop: '20px' }}>
      <StyledPaper
        elevation={6}
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Typography variant="h4" sx={{ mb: 3, color: mode === 'dark' ? 'white' : 'black' }}>
          Thank You!
        </Typography>
        <Typography variant="body1" sx={{ mb: 3, color: mode === 'dark' ? 'white' : 'black' }}>
          Your response has been submitted successfully.
        </Typography>
      </StyledPaper>
    </Box>
  );
}

export default ThankYou;
