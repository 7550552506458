import React, { useState, useEffect, useRef } from 'react';
import { 
  List, ListItem, ListItemAvatar, ListItemText, Avatar, Typography, 
  CircularProgress, TextField, Box, Paper, Button, IconButton, Chip, ImageList, ImageListItem
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion, AnimatePresence } from 'framer-motion';
import SendIcon from '@mui/icons-material/Send';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import ReplyIcon from '@mui/icons-material/Reply';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { useAuth } from '../contexts/AuthContext';
import { useTheme } from '../contexts/ThemeContext';
import { useNavigate } from 'react-router-dom';
import api from '../utils/api';
import EmbedEditor from './EmbedEditor';

const FullscreenBox = styled(Box)({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  boxSizing: 'border-box',
  overflowX: 'hidden',
});

const StyledPaper = styled(motion(Paper))(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  width: '100%',
  maxWidth: '800px',
  height: '80vh',
}));

const AnimatedListItem = styled(motion(ListItem))(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(5px)',
  borderRadius: '8px',
  marginBottom: '8px',
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.2)',
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    color: theme.palette.mode === 'dark' ? 'white' : 'black',
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.mode === 'dark' ? 'white' : 'black',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
    },
    '&:hover fieldset': {
      borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
    },
  },
}));

const MessageContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const ReactionChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const AttachmentLink = styled('a')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.primary.main,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const MessageImage = styled('img')({
  maxWidth: '100%',
  maxHeight: '300px',
  objectFit: 'contain',
  borderRadius: '4px',
});

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? 'white' : 'black',
  borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
  '&:hover': {
    borderColor: theme.palette.mode === 'dark' ? 'white' : 'black',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

function MessageList({ channelId }) {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [hasMore, setHasMore] = useState(true);
  const [channelName, setChannelName] = useState('');
  const [oldestMessageTimestamp, setOldestMessageTimestamp] = useState(null);
  const { user } = useAuth();
  const { mode } = useTheme();
  const messagesEndRef = useRef(null);
  const navigate = useNavigate();
  const [embedEditorOpen, setEmbedEditorOpen] = useState(false);
  const [currentEmbed, setCurrentEmbed] = useState(null);

  useEffect(() => {
    if (channelId) {
      setMessages([]);
      setOldestMessageTimestamp(null);
      setHasMore(true);
      fetchMessages();
      fetchChannelInfo();
    } else {
      setError('No channel selected');
    }
  }, [channelId]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const fetchMessages = async () => {
    if (!channelId || loading) return;
    setLoading(true);
    setError(null);
  
    try {
      const response = await api.get(`/channels/${channelId}/messages`, {
        params: { 
          limit: 50, 
          before: oldestMessageTimestamp || undefined,
        }
      });
  
      // Handle ResponseHandler format
      const messageData = response.data.data;
      if (!messageData || !messageData.messages || !Array.isArray(messageData.messages)) {
        throw new Error('Invalid response format');
      }
  
      const newMessages = messageData.messages.map(msg => ({
        ...msg,
        author: msg.author || {
          id: msg.user_id || 'unknown',
          username: msg.username || 'Unknown User',
          avatar_url: msg.avatar_url || ''
        }
      }));
  
      console.log('Sample message:', newMessages[0]);
  
      setMessages(prevMessages => {
        const uniqueMessages = newMessages.filter(newMsg => !prevMessages.some(prevMsg => prevMsg.id === newMsg.id));
        return [...prevMessages, ...uniqueMessages];
      });
  
      setHasMore(newMessages.length === 50);
      
      if (newMessages.length > 0) {
        setOldestMessageTimestamp(newMessages[newMessages.length - 1].timestamp);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
      setError('Failed to fetch messages. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const fetchChannelInfo = async () => {
    if (!channelId) return;
    try {
      const response = await api.get(`/channels/${channelId}`);
      setChannelName(response.data.data.name);
    } catch (error) {
      console.error('Error fetching channel info:', error);
      setError('Failed to fetch channel information.');
    }
  };

  const handleLoadMore = () => {
    if (hasMore && !loading) {
      fetchMessages();
    }
  };

  const handleNewMessageChange = (event) => {
    setNewMessage(event.target.value);
  };

  const handleOpenEmbedEditor = () => {
    setEmbedEditorOpen(true);
  };

  const handleCloseEmbedEditor = () => {
    setEmbedEditorOpen(false);
  };

  const handleSaveEmbed = (embed) => {
    setCurrentEmbed(embed);
    handleCloseEmbedEditor();
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() === '' && !currentEmbed) return;
  
    try {
      const response = await api.post(`/channels/${channelId}/messages`, { 
        content: newMessage,
        embed: currentEmbed
      });
      
      const pelbotMessage = {
        ...response.data.data,
        author: {
          id: 'pelbot',
          username: 'Pelbot',
          avatar_url: 'https://pelicraft.net/public/logotrans.png'
        }
      };
      
      setNewMessage('');
      setCurrentEmbed(null);
      setMessages(prevMessages => [...prevMessages, pelbotMessage]);
      scrollToBottom();
    } catch (error) {
      console.error('Error sending message:', error);
      setError('Failed to send message. Please try again later.');
    }
  };

  const handleBackClick = () => {
    navigate('/channels');
  };

  const renderMessageContent = (message) => {
    return (
      <MessageContent>
        {message.reply_to && (
          <Box sx={{ display: 'flex', alignItems: 'center', color: 'text.secondary' }}>
            <ReplyIcon fontSize="small" sx={{ mr: 1 }} />
            Replying to a message
          </Box>
        )}
        <Typography sx={{ color: mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)' }}>
          {message.content}
        </Typography>
        {Array.isArray(message.attachments) && message.attachments.length > 0 && (
          <ImageList cols={Math.min(3, message.attachments.length)} rowHeight="auto">
            {message.attachments.map((attachment, index) => {
              if (typeof attachment === 'string') {
                return (
                  <ImageListItem key={index}>
                    <MessageImage src={attachment} alt={`Attachment ${index + 1}`} loading="lazy" />
                  </ImageListItem>
                );
              } else if (attachment && attachment.url) {
                return attachment.url.match(/\.(jpeg|jpg|gif|png)$/i) !== null ? (
                  <ImageListItem key={index}>
                    <MessageImage src={attachment.url} alt={attachment.name || `Attachment ${index + 1}`} loading="lazy" />
                  </ImageListItem>
                ) : (
                  <AttachmentLink key={index} href={attachment.url} target="_blank" rel="noopener noreferrer">
                    <AttachmentIcon fontSize="small" sx={{ mr: 1 }} />
                    {attachment.name || `Attachment ${index + 1}`}
                  </AttachmentLink>
                );
              } else {
                console.warn('Unexpected attachment format:', attachment);
                return null;
              }
            })}
          </ImageList>
        )}
        {Array.isArray(message.reactions) && message.reactions.length > 0 && (
          <Box>
            {message.reactions.map((reaction, index) => (
              <ReactionChip key={index} label={reaction.emoji} size="small" />
            ))}
          </Box>
        )}
      </MessageContent>
    );
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.05
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  if (!user) return null;
  if (!channelId) return <Typography color="error">No channel selected</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <FullscreenBox>
      <StyledPaper 
        elevation={6}
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Box display="flex" alignItems="center" width="100%" mb={2}>
          <IconButton onClick={handleBackClick} sx={{ color: mode === 'dark' ? 'white' : 'black' }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4" sx={{ color: mode === 'dark' ? 'white' : 'black', ml: 2 }}>
            {channelName || 'Loading...'}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1, width: '100%', overflowY: 'auto', display: 'flex', flexDirection: 'column-reverse', mb: 2 }}>
          <AnimatePresence mode="popLayout">
            <motion.div variants={containerVariants} initial="hidden" animate="visible">
              {messages.slice().reverse().map(message => (
                <AnimatedListItem key={message.id} variants={itemVariants} whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
                  <ListItemAvatar>
                    <Avatar 
                      src={message.author?.avatar_url || message.avatar_url} 
                      alt={message.author?.username || message.username || 'Unknown User'}
                    >
                      {(message.author?.username || message.username || '?').charAt(0)}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText 
                    primary={
                      <Typography sx={{ color: mode === 'dark' ? 'white' : 'black' }}>
                        {message.author?.username || message.username || 'Unknown User'}
                      </Typography>
                    }
                    secondary={renderMessageContent(message)}
                  />
                </AnimatedListItem>
              ))}
            </motion.div>
          </AnimatePresence>
          {hasMore && (
            <Button 
              onClick={handleLoadMore} 
              sx={{ alignSelf: 'center', color: mode === 'dark' ? 'white' : 'black', mb: 2 }}
              disabled={loading}
            >
              {loading ? 'Loading...' : 'Load More'}
            </Button>
          )}
        </Box>
        <Box display="flex" width="100%" gap={1}>
          <StyledTextField
            fullWidth
            variant="outlined"
            placeholder="Type a message..."
            value={newMessage}
            onChange={handleNewMessageChange}
            onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
          />
          <IconButton onClick={handleOpenEmbedEditor}>
            <AddIcon />
          </IconButton>
          <StyledButton
            variant="outlined"
            onClick={handleSendMessage}
            endIcon={<SendIcon />}
          >
            Send
          </StyledButton>
        </Box>
      </StyledPaper>
      <EmbedEditor
        open={embedEditorOpen}
        onClose={handleCloseEmbedEditor}
        onSave={handleSaveEmbed}
      />
    </FullscreenBox>
  );
}

export default MessageList;
