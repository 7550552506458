import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box, Typography, TextField, Button, Paper, Radio, RadioGroup, FormControlLabel, Checkbox,
  FormGroup, FormControl, FormLabel
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { useTheme } from '../contexts/ThemeContext';
import api from '../utils/api';

const StyledPaper = styled(motion(Paper))(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  width: '100%',
  maxWidth: '800px',
  margin: '0 auto',
}));

const GlassButton = styled(Button)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.2)',
  backdropFilter: 'blur(5px)',
  border: '1px solid rgba(255, 255, 255, 0.3)',
  color: theme.palette.mode === 'dark' ? '#fff' : '#000',
  fontWeight: 'bold',
  padding: theme.spacing(1, 3),
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.3)',
    boxShadow: '0 4px 12px rgba(31, 38, 135, 0.37)',
  },
  '&:disabled': {
    background: 'rgba(255, 255, 255, 0.1)',
    color: 'rgba(255, 255, 255, 0.4)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
  },
}));

const DotStepper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(1),
  margin: theme.spacing(0, 2),
}));

const Dot = styled(Box)(({ theme, active }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: active ? theme.palette.common.white : 'rgba(255, 255, 255, 0.5)',
  transition: 'background-color 0.3s ease',
}));

function FormViewer() {
  const { formId } = useParams();
  const navigate = useNavigate();
  const [form, setForm] = useState(null);
  const [responses, setResponses] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { mode } = useTheme();

  useEffect(() => {
    fetchForm();
  }, [formId]);
  
  const fetchForm = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/forms/${formId}`);
      console.log('Fetched form:', response.data);
      // Handle both direct object and wrapped data formats
      const formData = response.data?.data || response.data;
      if (!formData) {
        throw new Error('Form not found');
      }
      setForm(formData);
      setError(null);
    } catch (error) {
      console.error('Error fetching form:', error);
      setError('Failed to fetch form');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (elementId, value) => {
    setResponses(prev => ({ ...prev, [elementId]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Submitting form responses:', responses);
      const response = await api.post(`/forms/${formId}/responses`, { answers: responses });
      // Handle both direct object and wrapped data formats
      const responseData = response.data?.data || response.data;
      console.log('Form submitted successfully:', responseData);
      navigate(`/forms/${formId}/thankyou`);
    } catch (error) {
      console.error('Error submitting form:', error);
      console.error('Error details:', error.response?.data);
      setError('Failed to submit form');
    }
  };

  const nextPage = (e) => {
    e.preventDefault();
    setCurrentPage(prev => prev + 1);
  };

  const prevPage = (e) => {
    e.preventDefault();
    setCurrentPage(prev => prev - 1);
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!form) return <Typography>Form not found</Typography>;

  const pages = form.elements ? form.elements.reduce((acc, element) => {
    if (element.type === 'pageBreak' || acc.length === 0) {
      acc.push([]);
    }
    if (element.type !== 'pageBreak') {
      acc[acc.length - 1].push(element);
    }
    return acc;
  }, []) : [];

  if (pages.length === 0) {
    return (
      <Typography>
        This form doesn't have any elements yet.
      </Typography>
    );
  }

  const currentPageElements = pages[currentPage] || [];
  const isMultiPage = pages.length > 1;

  return (
    <Box sx={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <StyledPaper
        elevation={6}
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Typography variant="h4" sx={{ mb: 3, color: mode === 'dark' ? 'white' : 'black' }}>
          {form.title}
        </Typography>
        <Typography variant="body1" sx={{ mb: 4 }}>
          {form.description}
        </Typography>
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          {currentPageElements.map((element, index) => (
            <Box key={index} sx={{ mb: 4 }}>
              {element.type === 'title' && (
                <Typography variant="h5" sx={{ mb: 2 }}>{element.content}</Typography>
              )}
              {element.type === 'text' && (
                <Typography variant="body1">{element.content}</Typography>
              )}
              {element.type === 'question' && (
                <FormControl component="fieldset" fullWidth required={element.required}>
                  <FormLabel component="legend">{element.content}</FormLabel>
                  {(element.questionType === 'text' || !element.questionType) && (
                    <TextField
                      fullWidth
                      value={responses[element.id] || ''}
                      onChange={(e) => handleInputChange(element.id, e.target.value)}
                    />
                  )}
                  {element.questionType === 'longText' && (
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      value={responses[element.id] || ''}
                      onChange={(e) => handleInputChange(element.id, e.target.value)}
                    />
                  )}
                  {element.questionType === 'multipleChoice' && (
                    <RadioGroup
                      value={responses[element.id] || ''}
                      onChange={(e) => handleInputChange(element.id, e.target.value)}
                    >
                      {element.options.map((option, optionIndex) => (
                        <FormControlLabel
                          key={optionIndex}
                          value={option}
                          control={<Radio />}
                          label={option}
                        />
                      ))}
                    </RadioGroup>
                  )}
                  {element.questionType === 'checkbox' && (
                    <FormGroup>
                      {element.options.map((option, optionIndex) => (
                        <FormControlLabel
                          key={optionIndex}
                          control={
                            <Checkbox
                              checked={(responses[element.id] || []).includes(option)}
                              onChange={(e) => {
                                const currentValues = responses[element.id] || [];
                                const newValues = e.target.checked
                                  ? [...currentValues, option]
                                  : currentValues.filter(value => value !== option);
                                handleInputChange(element.id, newValues);
                              }}
                            />
                          }
                          label={option}
                        />
                      ))}
                    </FormGroup>
                  )}
                </FormControl>
              )}
              {element.type === 'image' && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                  <img src={element.content} alt="Form image" style={{ maxWidth: '100%', height: 'auto' }} />
                </Box>
              )}
            </Box>
          ))}
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
            {isMultiPage ? (
              <>
                <GlassButton 
                  onClick={prevPage} 
                  disabled={currentPage === 0}
                >
                  Previous
                </GlassButton>
                <DotStepper>
                  {pages.map((_, index) => (
                    <Dot key={index} active={index === currentPage} />
                  ))}
                </DotStepper>
                {currentPage < pages.length - 1 ? (
                  <GlassButton onClick={nextPage}>
                    Next
                  </GlassButton>
                ) : (
                  <GlassButton type="submit">
                    Submit
                  </GlassButton>
                )}
              </>
            ) : (
              <GlassButton type="submit">
                Submit
              </GlassButton>
            )}
          </Box>
        </form>
      </StyledPaper>
    </Box>
  );
}

export default FormViewer;
