// src/components/QuestionEditor.js
import React from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  Box, List, ListItem, IconButton, TextField, FormControl, InputLabel, Select, MenuItem,
  Checkbox, FormControlLabel, Grid, Tooltip, Paper, Typography, Button, Divider
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ShortTextIcon from '@mui/icons-material/ShortText';
import SubjectIcon from '@mui/icons-material/Subject';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import ImageIcon from '@mui/icons-material/Image';
import TitleIcon from '@mui/icons-material/Title';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import { styled } from '@mui/material/styles';

const ElementButton = styled(Paper)(({ theme }) => ({
  cursor: 'pointer',
  transition: 'all 0.3s',
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 6px 8px rgba(0, 0, 0, 0.15)',
  },
}));

const QuestionBox = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  transition: 'all 0.3s',
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.1)',
    boxShadow: '0 6px 8px rgba(0, 0, 0, 0.15)',
  },
}));

const elementTypes = [
  { type: 'text', icon: <ShortTextIcon />, label: 'Text' },
  { type: 'question', icon: <SubjectIcon />, label: 'Question' },
  { type: 'title', icon: <TitleIcon />, label: 'Title' },
  { type: 'image', icon: <ImageIcon />, label: 'Image' },
  { type: 'pageBreak', icon: <ViewStreamIcon />, label: 'Page Break' },
];

const questionTypes = [
  { value: 'text', label: 'Short Text' },
  { value: 'longText', label: 'Long Text' },
  { value: 'multipleChoice', label: 'Multiple Choice' },
  { value: 'checkbox', label: 'Checkbox' },
];

const DraggableElement = ({ element, index, moveElement, removeElement, updateElement }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'element',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [, drop] = useDrop(() => ({
    accept: 'element',
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveElement(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  }));

  return (
    <ListItem
      ref={(node) => drag(drop(node))}
      sx={{
        padding: 0,
        marginBottom: 2,
      }}
    >
      <QuestionBox
        sx={{
          opacity: isDragging ? 0.5 : 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <Box sx={{ cursor: "move", mr: 2 }}>
            <DragIndicatorIcon />
          </Box>
          <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
            {elementTypes.find((et) => et.type === element.type)?.label || "Question"}
          </Typography>
          <IconButton onClick={() => removeElement(index)} size="small">
            <DeleteIcon />
          </IconButton>
        </Box>

        {element.type !== "pageBreak" && (
          <TextField
            fullWidth
            label={element.type === "title" ? "Title" : "Question"}
            value={element.content || ""}
            onChange={(e) => updateElement(index, "content", e.target.value)}
            sx={{ mb: 2 }}
          />
        )}

        {element.type === 'question' && (
          <>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Question Type</InputLabel>
              <Select
                value={element.questionType || 'text'}
                onChange={(e) => updateElement(index, 'questionType', e.target.value)}
              >
                {questionTypes.map((type) => (
                  <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
            {['multipleChoice', 'checkbox'].includes(element.questionType) && (
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle2" sx={{ mb: 1 }}>Options:</Typography>
                {(element.options || []).map((option, optionIndex) => (
                  <Box key={optionIndex} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <TextField
                      fullWidth
                      value={option}
                      onChange={(e) => updateElement(index, 'options', (element.options || []).map((opt, i) => i === optionIndex ? e.target.value : opt))}
                      size="small"
                    />
                    <IconButton onClick={() => updateElement(index, 'options', (element.options || []).filter((_, i) => i !== optionIndex))} size="small">
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))}
                <Button startIcon={<AddIcon />} onClick={() => updateElement(index, 'options', [...(element.options || []), ''])} size="small">
                  Add Option
                </Button>
              </Box>
            )}
          </>
        )}

        {element.type === "image" && (
          <TextField
            fullWidth
            label="Image URL"
            value={element.content || ""}
            onChange={(e) => updateElement(index, "content", e.target.value)}
            sx={{ mb: 2 }}
          />
        )}

        {element.type !== "pageBreak" && element.type !== "title" && (
          <FormControlLabel
            control={
              <Checkbox
                checked={element.required || false}
                onChange={(e) => updateElement(index, "required", e.target.checked)}
              />
            }
            label="Required"
          />
        )}

        {element.type === "pageBreak" && (
          <Divider sx={{ my: 1 }}>Page Break</Divider>
        )}
      </QuestionBox>
    </ListItem>
  );
};

function QuestionEditor({ form, setForm }) {
  const addElement = (type) => {
    setForm(prevForm => ({
      ...prevForm,
      elements: [...prevForm.elements, { 
        type, 
        content: '', 
        questionType: type === 'question' ? 'text' : null,
        options: [], 
        required: false 
      }]
    }));
  };

  const updateElement = (index, field, value) => {
    setForm(prevForm => {
      const updatedElements = [...prevForm.elements];
      updatedElements[index] = { ...updatedElements[index], [field]: value };
      return { ...prevForm, elements: updatedElements };
    });
  };

  const removeElement = (index) => {
    setForm(prevForm => ({
      ...prevForm,
      elements: prevForm.elements.filter((_, i) => i !== index)
    }));
  };

  const moveElement = (fromIndex, toIndex) => {
    setForm((prevForm) => {
      const newElements = [...prevForm.elements];
      const [movedElement] = newElements.splice(fromIndex, 1);
      newElements.splice(toIndex, 0, movedElement);
      return { ...prevForm, elements: newElements };
    });
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Box>
        <Grid container spacing={2} sx={{ mb: 3 }}>
          {elementTypes.map((elementType) => (
            <Grid item xs={4} sm={3} key={elementType.type}>
              <Tooltip title={`Add ${elementType.label}`}>
                <ElementButton onClick={() => addElement(elementType.type)}>
                  {elementType.icon}
                  <Typography variant="caption" sx={{ mt: 1 }}>
                    {elementType.label}
                  </Typography>
                </ElementButton>
              </Tooltip>
            </Grid>
          ))}
        </Grid>
        <List sx={{ width: '100%' }}>
          {form.elements.map((element, index) => (
            <DraggableElement
              key={index}
              element={element}
              index={index}
              moveElement={moveElement}
              removeElement={removeElement}
              updateElement={updateElement}
            />
          ))}
        </List>
      </Box>
    </DndProvider>
  );
}

export default QuestionEditor;
