// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate, useNavigate, useParams, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Container, Button, Box, IconButton, Drawer, List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import PeopleIcon from '@mui/icons-material/People';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ForumIcon from '@mui/icons-material/Forum';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SettingsIcon from '@mui/icons-material/Settings';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { ThemeProviderWrapper, useTheme } from './contexts/ThemeContext';
import ThemedBackground from './components/ThemedBackground';
import PageTransition from './components/PageTransition';

// Import all your page components
import HomePage from './components/HomePage';
import Login from './components/Login';
import UserList from './components/UserList';
import UserDetails from './components/UserDetails';
import ServerStats from './components/ServerStats';
import ChannelList from './components/ChannelList';
import MessageList from './components/MessageList';
import AuditLogList from './components/AuditLogList';
import FormManager from './components/FormManager';
import FormEditor from './components/FormEditor';
import FormViewer from './components/FormViewer';
import ThankYou from './components/ThankYou';
import UserSettings from './components/ThankYou';
import PrivacyPolicy from './components/ThankYou';
import FormResponses from './components/FormResponses';
import StatusManager from './components/StatusManager';
import NewFormPrompt from './components/NewFormPrompt';
import Eula from './components/Eula';

const drawerWidth = 240;

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  background: alpha(theme.palette.background.paper, theme.palette.mode === 'dark' ? 0.5 : 0.7),
  backdropFilter: 'blur(10px)',
  boxShadow: 'none',
  borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    background: alpha(theme.palette.background.paper, theme.palette.mode === 'dark' ? 0.5 : 0.7),
    backdropFilter: 'blur(10px)',
    borderRight: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  },
}));

const ActiveListItem = styled(ListItem)(({ theme, active }) => ({
  backgroundColor: active ? alpha(theme.palette.primary.main, 0.1) : 'transparent',
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
  },
  transition: 'background-color 0.3s',
}));



const DrawerFooter = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  padding: theme.spacing(2),
  textAlign: 'center',
  borderTop: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
}));

function PrivateRoute({ children }) {
  const { user, loading } = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!loading && !user) {
      navigate('/');
    }
  }, [user, loading, navigate]);

  if (loading) {
    return <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <Typography variant="h6">Loading...</Typography>
    </Box>;
  }

  return user ? children : null;
}

function ChannelPage() {
  const { channelId } = useParams();
  
  if (!channelId) {
    return <Typography>Please select a channel</Typography>;
  }
  
  return <MessageList channelId={channelId} />;
}

function App() {
  const { user, loading, logout } = useAuth();
  const { colorMode, mode } = useTheme();
  const theme = useTheme();
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const NavItem = ({ to, icon, primary }) => (
    <ActiveListItem 
      button 
      component={Link} 
      to={to} 
      onClick={toggleDrawer}
      active={location.pathname === to}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={primary} />
    </ActiveListItem>
  );

  const drawerContent = (
    <div>
      <Toolbar />
      <List>
        <NavItem to="/settings" icon={<SettingsIcon />} primary="Settings" />
        {user && (
          <>
            <NavItem to="/users" icon={<PeopleIcon />} primary="Users" />
            <NavItem to="/stats" icon={<AssessmentIcon />} primary="Stats" />
            <NavItem to="/logs" icon={<ListAltIcon />} primary="Logs" />
            <NavItem to="/forms" icon={<ListAltIcon />} primary="Forms" />
            <NavItem to="/channels" icon={<ForumIcon />} primary="Channels" />
          </>
        )}
        <NavItem to="/status" icon={<AssessmentIcon />} primary="Statuses" />
      </List>
      <Divider />
      {user && (
        <List>
          <ListItem button onClick={() => { logout(); toggleDrawer(); }}>
            <ListItemIcon><ExitToAppIcon /></ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      )}
    </div>
  );

  if (loading) {
    return <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <Typography variant="h6">Loading...</Typography>
    </Box>;
  }

  return (
    <>
      <ThemedBackground />
      <StyledAppBar position="fixed">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Pelbot
          </Typography>
          <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
            {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
          {!user && (
            <Button color="inherit" component={Link} to="/login">Login</Button>
          )}
        </Toolbar>
      </StyledAppBar>
      <StyledDrawer
        variant="temporary"
        open={drawerOpen}
        onClose={toggleDrawer}
        ModalProps={{
          keepMounted: true,
        }}
      >
        {drawerContent}
      </StyledDrawer>
      <PageTransition>
        <Container>
          <Box sx={{ paddingTop: '64px', minHeight: '100vh' }}>
            <Routes location={location} key={location.pathname}>
              <Route path="/" element={user ? <Navigate to="/users" /> : <HomePage />} />
              <Route path="/login" element={user ? <Navigate to="/users" /> : <Login />} />
              <Route path="/users" element={<PrivateRoute><UserList /></PrivateRoute>} />
              <Route path="/users/:userId" element={<PrivateRoute><UserDetails /></PrivateRoute>} />
              <Route path="/stats" element={<PrivateRoute><ServerStats /></PrivateRoute>} />
              <Route path="/channels" element={<PrivateRoute><ChannelList /></PrivateRoute>} />
              <Route path="/logs" element={<PrivateRoute><AuditLogList /></PrivateRoute>} />
              <Route path="/channels/:channelId" element={<PrivateRoute><ChannelPage /></PrivateRoute>} />
              <Route path="/status" element={<StatusManager />} />
              <Route path="/forms" element={<FormManager />} />
              <Route path="/forms/new" element={<NewFormPrompt />} />
              <Route path="/forms/:formId/edit" element={<FormEditor />} />
              <Route path="/forms/:formId" element={<FormViewer />} />
              <Route path="/forms/:formId/thankyou" element={<ThankYou />} />
              <Route path="/forms/:formId/responses" element={<PrivateRoute><FormResponses /></PrivateRoute>} />
              <Route path="/eula" element={<Eula />} />
              <Route path="/settings" element={<PrivateRoute><UserSettings /></PrivateRoute>} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Box>
        </Container>
      </PageTransition>
    </>
  );
}

function AppWithProviders() {
  return (
    <AuthProvider>
      <ThemeProviderWrapper>
        <Router>
          <App />
        </Router>
      </ThemeProviderWrapper>
    </AuthProvider>
  );
}

export default AppWithProviders;
